.sectionTitle {
	font-size: 1rem;
	font-family: Poppins Bold;
	line-height: 2rem;
	text-transform: uppercase;
	text-align: center;
	color: #636363;
}
.highlightSections {
	color: #636363;
	border: 1px solid #d0d0d0;
	padding: 10px 15px;
	border-radius: 20px;
	margin: 10px;

	.highlightSectionTitle {
		font-family: Poppins Bold;
	}
}
