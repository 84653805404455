@import '../global';

.creativeActionSection {
	@include flexRow;
	@include flexCenter;
	background-color: #fff;
	border-bottom: 4px solid;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-image-slice: 1;
	border-image-source: linear-gradient(
		to right,
		#f84c69,
		#f79759,
		#ebe478,
		#a0fb77,
		#64ed97,
		#57b2f3,
		#e475f3
	);
	margin: 0rem 0 0;

	.creativeActionWrapper {
		@include flexRow;
		justify-content: space-between;
		align-items: stretch;
		border: 1px solid #d0d0d0;
		border-bottom: 0;
		padding: 20px 0;
		width: 100%;
		flex-wrap: wrap;

		.creativeActionItem {
			@include flexColumn;
			@include flexSpaceBetween;
			flex-grow: 1;
			border-right: 0px solid #5f5f5f;
			padding: 10px 0;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			flex-grow: 1;
			border-right: 0px solid #5f5f5f;
			padding: 10px 0;
			flex-wrap: wrap;

			&:last-of-type {
				border-right: none;
			}
			.hexContainer{
				// background: url(../../assets/images/hexagoncontainer.png);
				width: 139px;
				height: auto;
				background-size: 100% auto;
				
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				flex-wrap: nowrap;
				
				@include md{
					width:111px;
				}
				@include sm{
					width:55px;
					background-size: 100% auto;
    				background-repeat: no-repeat;
				}
				@include xs{
					width:55px;
					background-size: 100% auto;
    				background-repeat: no-repeat;
				}
			}
			.hexContainer:hover{
				filter: grayscale(1) opacity(0.7);
			}
			.hexContainer img{
				width: 100%;
			}
			.iconContainer {
				width: 67%;
				height: auto;
				background-size: 100% auto;
				position: absolute;
				margin: 0% 0%;
				text-align: center;
			}
			.creativeQRCode {
				
				width: 65px !important;
    			height: 65px !important;
				@include md {
					width: 65px !important;
   			 		height: 65px !important;
				}
			}
			.qrCodeContainer{
				display: flex;
				flex-direction: column;
				align-content: center;
				justify-content: center;
				align-items: center;
				padding-top:0px;
				height:144px;
				.creativeActionText {
					font-family: 'Poppins Bold';
					font-size: 0.7rem;
					color: $text-color;
					line-height: 1.2;
					text-align: center;
					margin-top: 3px;
					@include md {
						font-size: 0.75rem;
					}
				}
			}
			.creativeActionText {
				font-family: 'Poppins Regular';
				font-size: 0.9rem;
				color: $text-color;
				line-height: 1.2;
				text-align: center;
				@include md {
					font-size: 0.75rem;
				}
			}
			.creativeActionImage {
				@include flexRow;
				align-items: flex-end;

				.icon {
					@include flexRow;
					color: #5f5f5f;
					font-size: 4.5rem;
					@include md {
						font-size: 3rem;
					}
				}
				
				.linkIcon {
					font-size: 5.2rem;
					transform: rotate(-45deg);
					position: relative;
					top: 7px;
					@include md {
						font-size: 3.7rem;
						top: 5px;
					}
				}
			}
		}
		.qrCodeActionItem{
			display:flex;
			@include lg {
				display:flex;
			}
			@include xl{
				display: none;
			}
			@include md {
				display:none;
			}
			@include sm {
				display:none;
			}
			@include xs{
				display: none;
			}
		}
	}
	
}
