$screen-xs-max: 320px;
$screen-sm-max: 480px;
// Media Query Mixins
@mixin xs {
	@media (max-width: #{$screen-xs-max}) {
		@content;
	}
}
@mixin sm {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}

.cubeIcon{
    
    width: 100%;
    display: flex;
    justify-content: center;
    top: 21px;
    position: relative;
    img{
        width: 40px;
        @include sm{
            width: 30px;
        }
    }
}