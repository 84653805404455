@import './global';

.homeBanner {
	@include flexRow;
	@include flexCenter;
	margin-top: 64px;
	background: linear-gradient(
		90deg,
		#f84c69,
		#f79759,
		#ebe478,
		#a0fb77,
		#64ed97,
		#57b2f3,
		#e475f3,
		#f84c69,
		#f79759,
		#ebe478,
		#a0fb77,
		#64ed97,
		#57b2f3,
		#e475f3,
		#f84c69
	);
	background-size: 400% 100%;
	animation: gradient 120s linear infinite forwards;
	color: #fff;
	height: 200px;
	position: relative;
	@include lg {
		height: 180px;
	}
	@include md {
		height: 160px;
	}
	@include sm {
		height: 120px;
	}
	@keyframes gradient {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: 400% 0%;
		}
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url('../assets/images/banner_grid.png');
		background-size: 3.6rem;
		opacity: 0.25;
	}

	.bannerContent {
		@include flexColumn;
		@include flexCenter;
		height: 100%;
		width: 100%;
		max-width: 1800px;
		margin: 0 50px;
		@include lg {
			margin: 0;
			padding: 0 7%;
		}

		.bannerHeading {
			font-family: 'Poppins Black';
			text-align: center;
			text-transform: uppercase;
			font-size: 3.5rem;
			@include lg {
				font-size: 3.2rem;
			}
			@include md {
				font-size: 3rem;
			}
			@include sm {
				font-size: 2.8rem;
			}
			@include xs {
				font-size: 2.6rem;
			}
		}
		.heroImg {
			max-width: 100%;
			height: 100%;
		}
	}
}
