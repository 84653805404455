@import '../global';

.creativeInfo {
	color: $text-color;
	width: 100%;
	.creativeInfoHeader {
		display: flex;
		justify-content: space-between;
		@include xl {
			display: block;
		}
	}
	.creativeTitle {
		font-size: 2rem;
		font-family: 'Poppins Bold';
		line-height: 2.5rem;
		text-transform: uppercase;
		width: 70%;
		@include xl {
			width: 100%;
		}
		@include lg {
			font-size: 2rem;
		}
		@include md {
			font-size: 1.8rem;
		}
		@include sm {
			font-size: 1.5rem;
			line-height: 1.5rem;
		}
	}
	.creativeDate {
		@include flexRow;

		font-size: 0.8rem;
		font-family: 'Poppins Medium';
		margin: 10px 0 0;
		text-transform: uppercase;
		align-items: flex-end;

		@include sm {
			margin: 0;
		}

		.creativeCountry {
			padding: 0 0 0 5px;
			text-transform: uppercase;
		}
	}
	.creativeMockupContainer {
		border-radius: 15px;
		border: 1px solid #d85c58;
		margin-left: 3px;
		display: flex;
		color: #d85c58;
		font-family: 'Poppins Regular';
		.mockup {
			margin: 0px 9px;
			// color: red;
			font-size: 0.7rem;
		}
	}
	.creativeDescription {
		background-color: #fff;
		border: 1px solid #d0d0d0;
		padding: 15px;
		font-size: 0.8rem;
		font-family: 'Poppins Regular';
		// margin: 1rem 0 0;
		line-height: 1rem;
		text-align: justify;
		min-height: 10px;
		p {
			padding: 8px 0;
		}
	}
	// .creativeDescription p:not(:first-child) {
	// 	margin-top:5px;
	// }
	.creativeAttachment {
		width: 100%;
		margin: 2rem 0 0;
		text-align: center;

		video,
		img {
			max-width: 100%;
			max-height: 75vh;
			@include md {
				max-height: 70vh;
			}
			@include sm {
				max-height: 65vh;
			}
		}
	}
	.attachmentCarouselCtn {
		margin: 0 auto;
		width: 40%;
	}
	.attachmentCarouselSection {
		margin-top: 6%;
	}
	.attachmentCarouselSection video {
		width: 100%;
	}
	.attachmentAttachment {
		width: 40%;
		margin: 0 auto;
	}
	.wraaperContainer {
		padding: 3px;
		box-shadow: 0px 0px 3px 0px #88888885;
		border-radius: 2px;
		margin-top: 10px;
	}
	.readMoreIcon {
		text-align: center;
		font-weight: bold;
		font-family: 'Poppins SemiBold';
		font-size: 0.9rem;
	}
	.upperWrapper {
		padding: 3px;
		box-shadow: 0px 0px 3px 0px #88888885;
		border-radius: 2px;
		margin-top: 14px;
	}
	.creativeCarouselSection .carousel-slider .control-arrow {
		top: calc(50% - 15px);
		color: #fff;
		font-size: 26px;
		bottom: 0;
		margin-top: 0;
		padding: 5px;
		height: 40px;
		border-radius: 50%;
		width: 40px;
		vertical-align: middle;
		opacity: 0.8;
		background: rgba(0, 0, 0, 0.2);
	}

	.carousel.carousel-slider .control-arrow {
		top: calc(50% - 15px);
		color: #fff;
		font-size: 26px;
		bottom: 0;
		margin-top: 0;
		padding: 5px;
		height: 40px;
		border-radius: 50%;
		width: 40px;
		vertical-align: middle;
		opacity: 0.8;
		background: rgba(0, 0, 0, 0.2);
	}
	.carousel.carousel-slider .control-arrow:hover {
		background: rgba(0, 0, 0, 0.2);
	}
	.button {
		font-family: 'Poppins Medium';
		margin-top: 5px;
		background-color: #ea5252;
		color: #ffffff;
		border-radius: 50px;
		text-transform: none;
		padding: 0px 25px;
		max-height: 40px;

		@include xl {
			padding: 5px 15px;
		}
		@include lg {
			font-size: 0.6rem;
		}
		// @include xs {
		// 	font-size: 1rem;
		// }
		// @include sm {
		// 	font-size: 1rem;
		// }
	}
	.button:hover {
		background-color: #ea5252 !important;
	}
}
