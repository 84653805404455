$primary-color: #0a4dcc;
$secondary-color: #fe7c37;
$text-color: #636363;
$screen-xs-max: 499px;
$screen-sm-max: 599px;
$screen-md-max: 768px;
$screen-lg-max: 900px;
$screen-xl-max: 1200px;
// Media Query Mixins
@mixin xs {
	@media (max-width: #{$screen-xs-max}) {
		@content;
	}
}
@mixin sm {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}
@mixin md {
	@media (max-width: #{$screen-md-max}) {
		@content;
	}
}
@mixin lg {
	@media (max-width: #{$screen-lg-max}) {
		@content;
	}
}
@mixin xl {
	@media (max-width: #{$screen-xl-max}) {
		@content;
	}
}
// Placeholder Mixin
@mixin placeholder {
	::-webkit-input-placeholder {
		@content;
	}
	:-moz-placeholder {
		@content;
	}
	::-moz-placeholder {
		@content;
	}
	:-ms-input-placeholder {
		@content;
	}
}
// Global Class Mixins
@mixin flexRow {
	display: flex;
	flex-direction: row;
}
@mixin flexColumn {
	display: flex;
	flex-direction: column;
}
@mixin flexCenter {
	justify-content: center;
	align-items: center;
}
@mixin flexSpaceBetween {
	justify-content: space-between;
	align-items: center;
}
@mixin flexStart {
	justify-content: flex-start;
	align-items: center;
}
@mixin flexEnd {
	justify-content: flex-end;
	align-items: center;
}
@mixin flexVAlign {
	align-items: center;
}
@mixin hidden {
	display: none !important;
}
.hidden {
	display: none !important;
}
.invisible {
	visibility: hidden;
}
