@import './global';

.header {
	background-color: #fff !important;
	min-height: 64px;

	.toolbar {
		padding: 0 !important;
		@include sm {
			min-height: 64px;
		}

		.headerContent {
			@include flexRow;
			@include flexSpaceBetween;
			padding: 0 7%;
			width: 100%;
			height: 100%;
			min-height: 56px;
			border-top: 1px solid #d0d0d0;
			border-bottom: 1px solid #d0d0d0;
			@include sm {
				justify-content: flex-start;
				padding: 0 5%;
			}

			.backBtn {
				padding: 0;
				margin: 0 20px 0 0;
				display: none;
				color: #b1b1b1;
				@include sm {
					display: flex;
				}

				.backIcon {
					font-size: 1.8rem;
				}
			}
			.menuBtnCtn {
				@include flexRow;
				@include flexCenter;
				position: relative;
				padding: 0;
				margin: 0 15px 0 0;
				display: none;
				@include sm {
					display: flex;
				}

				.menuHexagon {
					color: #b1b1b1;
					font-size: 2.2rem;
					position: relative;
					top: -2px;
				}
				.menuBtn {
					position: absolute;

					.menuIcon {
						color: #fff;
						font-size: 1.5rem;
					}
				}
			}
			.headerLogoCtn {
				@include flexRow;
				@include flexStart;
				width: 60%;
				@include md {
					@include flexColumn;
					@include flexCenter;
					width: 35%;
				}
				@include sm {
					width: 37%;
				}
				@include xs {
					width: 57%;
				}

				.headerLogo {
					@include flexRow;
					@include flexCenter;
					margin: 0 15px 0 0;
					width: 40%;
					max-width: 15rem;
					@include md {
						margin: 0;
						width: 100%;
					}

					img {
						width: 100%;
					}
				}
				.headerSubText {
					color: $text-color;
					font-family: 'Poppins Light';
					font-size: 0.7rem;
					text-align: center;
					@include md {
						font-size: 0.5rem;
						margin: 2px 0 0;
					}
				}
			}
			.headerBtnCtn {
				@include flexRow;
				@include flexEnd;
				color:black;
				@include sm {
					display: none;
				}
				.galleryLink{
					margin-right: 10px;;
				}
				.headerBtn {
					color: $text-color !important;
					font-size: 1.05rem;
					font-family: 'Poppins Light';
					text-transform: capitalize;
					margin: 0 0.5rem 0 0.5rem;
					@include md {
						font-size: 0.95rem;
						margin: 0 1.5rem 0 0;
					}

					// &:last-of-type {
					// 	margin: 0;
					// 	margin-right: 10px;;
					// }
				}
				.headerBtn:hover{
					font-family: 'Poppins Regular';
				}
				.headerBtn:active{
					font-family:'Poppins Regular' ;
				}
			}
		}
	}
}
