@import '../global';

.creativeCarouselSection {
	min-width: 25%;
	width: 25%;
	margin: 0 5% 0 0;
	@include lg {
		min-width: 30%;
		width: 30%;
	}
	@include md {
		min-width: 38%;
		width: 38%;
	}
	@include sm {
		min-width: 50%;
		width: 50%;
		margin: 0 0 3rem;
	}
	@include xs {
		min-width: 65%;
		width: 65%;
		margin: 0 0 2rem;
	}

	.creativeCarouselCtn {
		position: sticky;
		top: calc(64px + 5%);
		width: 90%;

		.creativeCarousel {
			position: absolute;
			width: 88%;
			top: 10%;
			left: 6%;
			
		}
		.imageLoaderContainer{
			display: flex;
		position: absolute;
		top: 0;
		text-align: center;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		align-content: center;
		width: 100%;
		height: 100%;
		}
		.imageLoaderContainer img{
			width: 50%;
		}
	}
	.creativeCarouselSection .carousel-slider .control-arrow {
		top: calc(50% - 15px);
		color: #fff;
		font-size: 26px;
		bottom: 0;
		margin-top: 0;
		padding: 5px;
		height: 40px;
		border-radius: 50%;
		width: 40px;
		vertical-align: middle;
		opacity:0.8;
		background: rgba(0, 0, 0, 0.2);
	}
	
	.carousel.carousel-slider .control-arrow {
		top: calc(50% - 15px);
		color: #fff;
		font-size: 26px;
		bottom: 0;
		margin-top: 0;
		padding: 5px;
		height: 40px;
		border-radius: 50%;
		width: 40px;
		vertical-align: middle;
		opacity: 0.8;
    	background: rgba(0, 0, 0, 0.2);
	}
	.carousel.carousel-slider .control-arrow:hover {
		  background: rgba(0, 0, 0, 0.2);
	}
	
}
