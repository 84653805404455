/* Reset CSS */
* {
	margin: 0;
	padding: 0;
	outline-style: none;
	box-sizing: border-box;
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
}
/* External Fonts */
@font-face {
	font-family: 'Poppins Black';
	src: url('./assets/fonts/Poppins-Black.otf');
}
@font-face {
	font-family: 'Poppins Bold';
	src: url('./assets/fonts/Poppins-Bold.otf');
}
@font-face {
	font-family: 'Poppins SemiBold';
	src: url('./assets/fonts/Poppins-SemiBold.otf');
}
@font-face {
	font-family: 'Poppins ExtraBold';
	src: url('./assets/fonts/Poppins-ExtraBold.otf');
}
@font-face {
	font-family: 'Poppins Medium';
	src: url('./assets/fonts/Poppins-Medium.otf');
}
@font-face {
	font-family: 'Poppins Regular';
	src: url('./assets/fonts/Poppins-Regular.otf');
}
@font-face {
	font-family: 'Poppins Light';
	src: url('./assets/fonts/Poppins-Light.otf');
}
@font-face {
	font-family: 'Barlow Bold';
	src: url('./assets/fonts/Barlow-Bold.otf');
}
@font-face {
	font-family: 'Barlow SemiBold';
	src: url('./assets/fonts/Barlow-SemiBold.otf');
}
@font-face {
	font-family: 'Barlow Medium';
	src: url('./assets/fonts/Barlow-Medium.otf');
}
@font-face {
	font-family: 'Barlow Regular';
	src: url('./assets/fonts/Barlow-Regular.otf');
}
@font-face {
	font-family: 'Barlow Thin';
	src: url('./assets/fonts/Barlow-Thin.otf');
}
/* Generic classes */
html,
body {
	scroll-behavior: smooth;
	height: 100%;
}
#root {
	height: 100%;
	display: grid;
	grid-template-rows: 1fr auto;
}
.flex-row {
	display: flex;
	flex-direction: row;
}
.flex-column {
	display: flex;
	flex-direction: column;
}
.flex-center {
	justify-content: center;
	align-items: center;
}
.flex-space-between {
	justify-content: space-between;
	align-items: center;
}
.flex-start {
	justify-content: flex-start;
	align-items: center;
}
.flex-end {
	justify-content: flex-end;
	align-items: center;
}
.flex-valign {
	align-items: center;
}
.hidden {
	display: none !important;
}
/* Specific CSS */
body {
	background: url('./assets/images/body_grid.png');
	background-size: 1.8rem;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
		'Open Sans', 'Helvetica Neue', sans-serif;
}
#creative-list-ctn {
	padding: 5% 7%;
}
/* Creative Card Code Starts */


/* Creative Card Code Ends */
/* Creative Detail Page CSS */
#creative-details-wrapper {
	width: 100%;
}
.creative-info-section {
	background-image: url(./assets/images/new-homepage-banner-2.jpg);
	background-repeat: no-repeat;
	position: fixed;
	top: 64px;
	width: 100%;
	z-index: 10;
}
.creative-details {
	padding: 5% 0 5% 7%;
	width: 60%;
}
.objective {
	background: #9fb9eb;
	border-radius: 3px;
	padding: 5px 10px;
	font-family: 'Barlow SemiBold';
	color: #08255b;
	text-align: center;
	font-size: 0.7rem;
	margin-right: 20px;
}
.objective:last-of-type {
	margin: 0;
}
.meta-data {
	background: #d4d4d5;
	border-radius: 3px;
	padding: 5px 10px;
	font-family: 'Barlow SemiBold';
	color: #08255b;
	text-align: center;
	font-size: 0.7rem;
	margin-right: 20px;
}
.meta-data:last-of-type {
	margin: 0;
}
.creative-title {
	font-family: 'Poppins Black';
	color: #fff;
	text-transform: uppercase;
	font-size: 3rem;
	padding: 35px 0 25px;
	line-height: 1;
}
.small {
	visibility: hidden;
	height: 0;
	transition: height 0.5s;
}
.creative-description {
	font-family: 'Barlow Thin';
	color: #fff;
	line-height: 1.5;
}
#downloadpdf {
	background: #e1e1e1;
	font-family: 'Poppins Bold';
	margin-top: 35px;
	height: 38px;
	width: 200px;
	box-shadow: none;
}
#download-now {
	color: #073182 !important;
	font-size: 0.8rem;
	text-transform: uppercase;
	width: 100%;
}
.tab-bar-wrapper {
	background: rgba(7, 9, 12, 0.5) !important;
}
.tab-bar {
	margin-left: 7%;
}
.MuiTab-wrapper {
	font-family: 'Barlow Regular';
	font-size: 0.9rem;
	text-transform: capitalize;
}
.MuiTabs-indicator {
	background: #4d89ff !important;
	height: 3px !important;
}
#mobile-video-example {
	width: 248px;
	background-image: url(./assets/images/mobilescreen.png);
	height: 484px;
	background-size: 100% 100%;
	position: absolute;
	right: 7%;
	top: 15%;
}
#mobile-video-example video {
	width: 87%;
	position: absolute;
	top: 47px;
	left: 16px;
}
/* Usecase Section Starts */
.usecase-section {
	background: #fff;
	padding: 4% 7%;
}
#usecase-section-heading {
	color: #0b4dcc;
	font-family: 'Poppins Bold';
	font-size: 1.7rem;
	line-height: 1.3;
	text-transform: uppercase;
	width: 20%;
}
.usecase {
	width: 60px;
	margin: 0 40px;
	text-align: center;
	font-size: 0.9rem;
	font-family: 'Barlow Medium';
	color: #000;
}
.usecase-icons {
	width: 33px;
	height: 45px;
}
.usecase-icons img {
	width: 100%;
}
.entertainment {
	background-image: url(./assets/images/use_case/entertainment.png);
}
.retail {
	background-image: url(./assets/images/use_case/retail.png);
}
.telecom {
	background-image: url(./assets/images/use_case/telecom.png);
}
/* Usecase Section Ends */
/* Specification Section Starts */
.specification-section {
	background: #1f242e;
	color: #fff;
	min-height: 300px;
	font-family: 'Barlow Regular';
	padding: 4% 7% 3%;
}
#specification-section-heading {
	font-family: 'Poppins Medium';
	font-size: 1.7rem;
	text-transform: uppercase;
}
.build-spec-details {
	margin-top: 30px;
}
.specification-subsection {
	width: 15%;
	border-right: 1px solid rgba(255, 255, 255, 0.2);
	padding: 10px 0;
	margin-right: 50px;
}
.specification-subsection:last-of-type {
	border: none;
}
.specification-subheading {
	font-family: 'Barlow Medium';
	text-transform: uppercase;
	font-size: 1.1rem;
	padding-bottom: 10px;
}
.complexity-type {
	font-size: 0.75rem;
}
.complexity-bar {
	border: 1px solid #fff;
	width: 80%;
	margin-top: 4%;
}
.app-timeline {
	font-size: 1rem;
	margin-top: 7%;
}
.days-for-working {
	font-size: 0.85rem;
	padding-top: 5%;
}
.platform-icon {
	font-size: 1rem !important;
	position: absolute;
	left: -25px;
}
.supported-platform {
	opacity: 1;
	padding: 10px 0;
	position: relative;
}
.unsupported-platform {
	opacity: 0.3;
	padding: 10px 0;
	position: relative;
}
.supported-format {
	opacity: 1;
	padding: 10px 0;
}
.unsupported-format {
	opacity: 0.3;
	padding: 10px 0;
}
/* Specification Sections Ends */
/* Example Section Starts */
.example-section {
	background: #fff;
	color: #000;
	padding: 4% 7%;
}
#example-section-heading {
	font-family: 'Poppins Bold';
	color: #0b4dcc;
	font-size: 1.7rem;
	text-transform: uppercase;
}
.example-details {
	margin-top: 30px;
}
.example {
	margin-right: 70px;
	width: 12%;
}
.example-title {
	font-family: 'Barlow SemiBold';
	background: #0b4dcc;
	color: #fff;
	border-radius: 3px;
	padding: 7px 10px;
	margin-top: 20px;
	width: 100%;
	text-align: center;
	font-size: 0.9rem;
	text-transform: capitalize;
}
/* Example Section Ends */
/* Reporting Insights Section Starts */
.reporting-section {
	background: linear-gradient(to right, #0b4dcc, #073182);
	color: #fff;
	padding: 4% 7% 8%;
	position: relative;
}
#reporting-section-heading {
	font-family: 'Poppins Medium';
	color: #fff;
	text-transform: uppercase;
	font-size: 1.7rem;
}
.reporting-metrics-container {
	margin-top: 30px;
	width: 50%;
	flex-grow: 2;
	flex-wrap: wrap;
	font-family: 'Barlow Medium';
}
.reporting-metric {
	width: 50%;
	padding-bottom: 6px;
}
.reporting-icon {
	font-size: 1rem !important;
	margin-right: 5px;
}
.reporting-metric-text {
	font-size: 1rem;
}
.reporting-details {
	position: absolute;
	bottom: calc(-170px / 2);
	width: 45%;
}
.metric-card {
	height: 170px;
	width: 170px;
	background: #fff;
	box-shadow: 0px 1px 6px 2px rgba(11, 77, 204, 0.15);
	border-radius: 2px;
	padding: 10px;
	font-family: 'Barlow Bold';
	text-transform: uppercase;
	text-align: center;
	margin-right: 30px;
}
.metric-card-image {
	height: 30%;
}
.metric-card-image img {
	height: 100%;
}
.metric-card-value {
	color: #000;
	font-size: 1.9rem;
	padding: 20px 0 10px;
}
.metric-card-value-symbol {
	font-size: 0.8rem;
	padding-left: 5px;
}
.metric-card-key {
	color: #0b4dcc;
	font-size: 0.8rem;
}
/* Reporting Insights Section Ends */
/* FAQ Section Starts */
.faq-section {
	padding: 10% 7% 5%;
}
#faq-section-heading {
	color: #0b4dcc;
	text-transform: uppercase;
	font-family: 'Poppins Bold';
	font-size: 1.7rem;
}
.faqs-container {
	width: 60%;
	margin-top: 30px;
	flex-wrap: wrap;
}
.faq {
	width: 48%;
	margin-bottom: 2%;
}
.faq .panel {
	box-shadow: none;
	background: #f8f8f8;
}
.faq .panel.Mui-expanded {
	background: #fff;
	box-shadow: 0px 8px 16px 0 rgba(11, 77, 204, 0.15);
}
.faq .question {
	color: #0b4dcc;
	font-family: 'Barlow Medium';
	font-size: 1rem;
}
.faq .answer {
	color: #1f242e;
	font-family: 'Barlow Thin';
	font-size: 1rem;
}
/* FAQ Section Ends */
@media only screen and (max-width: 900px) and (min-width: 769px) {
	/* Creative Detail Page CSS */
	#mobile-video-example {
		width: 200px;
		height: 390px;
	}
	#mobile-video-example video {
		top: 32px;
		left: 13px;
	}
	#usecase-section-heading {
		font-size: 1.5rem;
	}
	.usecase {
		margin: 0 30px;
	}
	.example {
		margin-right: 40px;
		width: 18%;
	}
	.specification-subsection {
		width: 20%;
		margin-right: 40px;
	}
	.reporting-section {
		padding: 4% 7% 11%;
	}
	.reporting-details {
		bottom: calc(-130px / 2);
		width: 60%;
	}
	.metric-card {
		height: 130px;
		width: 130px;
		margin-right: 25px;
	}
	.metric-card-image {
		height: 35%;
	}
	.metric-card-value {
		font-size: 1.65rem;
		padding: 10px 0 8px;
	}
	.metric-card-key {
		font-size: 0.65rem;
	}
	.faq-section {
		padding: 14% 7% 5%;
	}
}
@media only screen and (max-width: 768px) and (min-width: 600px) {
	/* Creative Detail Page CSS */
	/* .creative-details {
		padding: 5% 0 5% 5%;
	} */
	.creative-title {
		font-size: 2.8rem;
	}
	.creative-description {
		font-size: 0.95rem;
	}
	#mobile-video-example {
		width: 180px;
		height: 351px;
		right: 5%;
		top: 5%;
	}
	#mobile-video-example video {
		top: 30px;
		left: 11px;
	}
	#usecase-section-heading {
		font-size: 1.3rem;
		margin-right: 30px;
	}
	.usecase {
		margin: 0 25px;
	}
	#specification-section-heading {
		font-size: 1.5rem;
	}
	.build-spec-details {
		font-size: 0.9rem;
		margin-top: 25px;
	}
	.specification-subsection {
		width: 33%;
		margin-right: 35px;
	}
	#example-section-heading {
		font-size: 1.5rem;
	}
	.example {
		width: 22%;
	}
	.reporting-section {
		padding: 4% 7% 15%;
	}
	#reporting-section-heading {
		font-size: 1.5rem;
	}
	.reporting-metrics-container {
		width: 80%;
	}
	.reporting-metric-text {
		font-size: 0.9rem;
	}
	.reporting-details {
		bottom: calc(-130px / 2);
		width: 80%;
	}
	.metric-card {
		height: 130px;
		width: 130px;
		margin-right: 25px;
	}
	.metric-card-image {
		height: 35%;
	}
	.metric-card-value {
		font-size: 1.65rem;
		padding: 10px 0 8px;
	}
	.metric-card-key {
		font-size: 0.65rem;
	}
	.faq-section {
		padding: 17% 7% 5%;
	}
	#faq-section-heading {
		font-size: 1.5rem;
	}
	.faqs-container {
		width: 100%;
	}
}
@media only screen and (max-width: 599px) {
	/* Creative Detail Page CSS */
	#mobile-video-example {
		position: relative;
		margin: 0 auto;
		top: auto;
		right: auto;
		width: 200px;
		height: 390px;
	}
	#mobile-video-example video {
		position: relative;
		top: -5px;
		left: auto;
	}
	.creative-info-section {
		position: inherit;
		margin-top: 56px;
		background-size: auto 100%;
		padding: 5% !important;
	}
	.creative-details {
		padding: 0 !important;
		width: 100%;
	}
	.creative-title {
		font-size: 2.5rem;
		padding: 20px 0px !important;
	}
	.creative-description {
		font-size: 0.9rem;
	}
	#downloadpdf {
		margin: 30px 0;
		width: 175px;
	}
	.tab-bar-wrapper {
		display: none !important;
	}
	.usecase-section {
		margin-top: 0;
		padding: 5%;
		flex-direction: column !important;
	}
	#usecase-section-heading {
		font-size: 1.4rem;
		width: 100%;
		/* margin-right: 30px; */
	}
	.usecase-details {
		margin-top: 20px;
		overflow-x: scroll;
		width: 100%;
	}
	.usecase {
		margin: 0 20px;
		font-size: 0.75rem;
		width: 50px;
	}
	.specification-section {
		padding: 5%;
	}
	#specification-section-heading {
		font-size: 1.4rem;
	}
	.build-spec-details {
		margin-top: 20px;
	}
	.specification-subsection {
		width: 33%;
		margin-right: 0;
		padding: 10px 10px 10px 25px;
	}
	.specification-subsection:first-of-type {
		padding-left: 0;
	}
	.specification-subheading {
		font-size: 0.95rem;
	}
	.complexity-type {
		font-size: 0.7rem;
	}
	.app-timeline {
		font-size: 0.85rem;
	}
	.days-for-working {
		font-size: 0.75rem;
	}
	.compatible-platforms {
		font-size: 0.8rem;
	}
	.platform-icon {
		font-size: 0.8rem !important;
		left: -15px;
	}
	.supported-formats {
		font-size: 0.8rem;
	}
	.example-section {
		padding: 5%;
	}
	#example-section-heading {
		font-size: 1.4rem;
	}
	.example-details {
		margin-top: 20px;
		overflow-x: scroll;
	}
	.example {
		margin-right: 20px;
		width: 25%;
	}
	.example > canvas {
		/* height: 80px;
		width: 80px; */
		width: 80% !important;
		height: auto !important;
	}
	.example-title {
		font-size: 0.8rem;
	}
	.reporting-section {
		padding: 5% 5% 20%;
	}
	#reporting-section-heading {
		font-size: 1.4rem;
	}
	.reporting-metrics-container {
		margin-top: 20px;
		width: 100%;
	}
	.reporting-metric-text {
		font-size: 0.85rem;
	}
	.reporting-details {
		bottom: calc(-120px / 2);
		width: 90%;
	}
	.metric-card {
		height: 120px;
		width: 120px;
		margin-right: 20px;
		justify-content: space-around;
	}
	.metric-card:last-of-type {
		margin-right: 0;
	}
	.metric-card-image {
		height: 30%;
	}
	.metric-card-value {
		font-size: 1.2rem;
		padding: 10px 0 8px;
	}
	.metric-card-value-symbol {
		font-size: 0.6rem;
	}
	.metric-card-key {
		font-size: 0.6rem;
	}
	.faq-section {
		padding: 20% 5% 5%;
	}
	#faq-section-heading {
		font-size: 1.4rem;
	}
	.faqs-container {
		width: 100%;
		margin-top: 20px;
	}
	.faq {
		width: 100%;
	}
	.faq .question {
		font-size: 0.9rem;
	}
	.faq .answer {
		font-size: 0.9rem;
	}
}
.creativeDetails_Details_Page {
    margin: 0 8%;
}