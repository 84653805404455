@import '../global';

.filterType {
	text-transform: capitalize;
	width: 20%;
	margin-bottom: 15px;
	font-size: 0.8rem !important;

	.filterTextField {
		label {
			font-family: 'Poppins Regular' !important;
			font-size: 0.9rem;
			@include lg {
				font-size: 0.8rem;
			}
			@include md {
				font-size: 0.75rem;
			}
		}
		& > div {
			background-color: #fff;
			border-radius: 20px;
		}
	}

	@include md {
		width: 100%;
	}
}

.MuiInputLabel-outlined.MuiInputLabel-marginDense {
	transform: translate(14px, 13px) scale(1) !important;
	@include lg {
		transform: translate(14px, 14px) scale(1) !important;
	}
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
	transform: translate(14px, -5px) scale(0.8) !important;
}
.MuiFormLabel-root.Mui-focused {
	color: $text-color !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: $text-color !important;
}
