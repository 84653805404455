
@import '../global.scss'; 
.detailsContainer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border: 1px solid #d0d0d0;
    border-bottom: 0;
    /* padding: 20px 0; */
    width: 100%;
    flex-wrap: nowrap;
    padding: 3px 3px;
    align-content: center;
    justify-content: flex-start;
    background: white;
    .verticalCardContainer {
        width: 100%;
    }
    .cardContainer{
        text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    }
    .left {
        width: 17%; 
        font-family: 'Poppins Bold';
        color: #636363;
        text-transform: uppercase;
        /* display: flex; */
    }
    .right {
        width: 100%;
        /* display: flex; */
        /* flex: 0 0; */
    }
    .details{
       
        width: 100%;
        margin: 0% 0;
        text-align: justify;
        font-family: 'Poppins Light';
        color: #636363;
        padding: 1% 1%;
        color: #636363;
        font-size: 0.9rem;
        position: relative;
        p {
            padding: 8px 0;
        }
        @include xs{
            padding:2% 3%;
        }
        @include sm{
            padding:2% 3%;
        }
        ol{
            @include sm{
               margin-left: 6%;
               width:90%;
            }
            @include xs{
                margin-left: 6%;
               width:90%
            }
        }
    }
    .creative-card {
        /* box-shadow: 0 2px 5px 1px rgb(0 0 0 / 10%) !important; */
        // height: 97%;
    border: 1px solid #a29c9c8c;
    // margin: 3px 3px;
    }
    .verticalIcon{
        width: 8% !important;
        /* border: 1px solid blue; */
        /* height: 100%; */
        display: flex;
        text-align: center;
        justify-content: center;
        align-content: center;
        position: relative;
        margin: 1% 1%;
        margin-right: 2%;
        float:left;
        @include xs {
            width: 39% !important;
            margin-right: 2%;
        }
        @include sm {
            width: 39% !important;
            margin-right: 2%;
        }
    }
    .verticalIcon img {
        width: 100%;
        filter: grayscale(1) opacity(0.8);
    }
    .ContainerContent .rightArea video{
        position: absolute;
        width: 90%;
        /* margin: 18% 4%; */
        position: absolute;
        width: 88%;
        top: 9%;
        left: 6%;
    }
    .hexagonbg img {
        width: 100%;
    }
    .hexagonbg {
        width: 91%;
        position: relative;
        @include sm {
            width: 100%;
        }
    }
}