@import './../global';

$screen-xs-max: 320px;
$screen-sm-max: 480px;
// Media Query Mixins
@mixin xs {
	@media (max-width: #{$screen-xs-max}) {
		@content;
	}
}
@mixin sm {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}
.awardWinningCreatives {
	width: 100%;
	background-color: #eaeaea;
	text-align: center;
	color: #636363;
	padding: 40px 20px;
	@include sm {
		padding: 40px 0px;
	}
	@include xs {
		width: 320px;
	}
	.heading {
		font-family: 'Poppins Bold';
		text-transform: uppercase;
		font-size: 1.8rem;
		letter-spacing: 0.1rem;
		@include lg {
			font-size: 1.3rem;
		}
		@include sm {
			font-size: 1rem;
		}
		@include xs {
			font-size: 0.8rem;
		}
	}
	.description {
		font-size: 1rem;
		font-family: 'Poppins Light';
		@include lg {
			font-size: 0.8rem;
		}
		@include sm {
			font-size: 0.8rem;
		}
		@include xs {
			font-size: 0.6rem;
		}
	}
	.button {
		font-family: 'Poppins Medium';
		margin-top: 15px;
		background-color: #ea5252;
		color: #ffffff;
		border-radius: 50px;
		text-transform: none;
		padding: 6px 25px;
		@include lg {
			font-size: 0.6rem;
		}
		@include md {
			padding: 5px 15px;
		}
		@include xs{
			font-size: 1rem;;
			padding: 6px 25px;
		}
		@include sm{
			font-size: 1rem;;
			padding: 6px 25px;
		}
	}
	.button:hover{
		background-color: #ea5252 !important; 
	}
	
}
.loaderInside{
	position: absolute;
}
.parentLoader{
	position: relative;
    background-color: "red";
    z-Index: 0
}
