.switch {
	display: flex;
	flex-direction: row-reverse !important;
	margin-top: -30px;
	* {
		font-family: 'Poppins Regular' !important;
	}
}
.NoMockMainContainer {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
	flex-direction: column;
	margin-bottom: 40px;
}
.NoMockContainer{
	float: left;
    display: flex;
}
.NoMockContainerRight {
    float: right;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}


@import url(https://fonts.googleapis.com/css?family=Roboto);

$md-chip-height: 26px;
$md-chip-color: #e0e0e0;

.md-chip {
  display: inline-block;
    background: #fafafa;
    padding: 6px 11px;
    border-radius: 32px;
    font-size: 0.85rem;
    color: #767474;
    border: 1px solid #ccc;
    font-family: 'Poppins Regular';
    margin-right: 1.2%;
  &.md-chip-hover:hover {
    background: #ccc;
  }
}

.md-chip-clickable {
 cursor: pointer;
}

.md-chip, .md-chip-icon {
  height: 40px;
    line-height: 26px;
}

.md-chip-icon {
  display: block;
  float: left;
  background: #009587;
  width: $md-chip-height;
  border-radius: 50%;
  text-align: center;
  color: white;
  margin: 0 8px 0 -12px;
}

.md-chip-remove {
  display: inline-block;
    background: #c7bebe;
    border: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    padding: 0;
    /* margin: 0 -4px 0 4px; */
    cursor: pointer;
    font: inherit;
    line-height: 0rem;
    color: #636363;
    border: 1px solid #ccc;
    font-size: 0.7rem;
  &:after { 
    // color: $md-chip-color;
    content: 'x';
    color: #6e6b6b;
    font-size: 0.9rem;
    font-family: 'Poppins Medium';
  }
  &:hover {
    background: #999;
  }
  &:active {
    background: #777;
  }
}

.md-chips {
  padding: 12px 0;
  .md-chip {
    margin: 0 5px 3px 0;
  } 
}

.md-chip-raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}



