@import './../global';

$screen-xs-max: 320px;
$screen-sm-max: 480px;
// Media Query Mixins
@mixin xs {
	@media (max-width: #{$screen-xs-max}) {
		@content;
	}
}
@mixin sm {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}

.banner {
	@include flexRow;
	@include flexCenter;
	// display: block;
	margin-top: 64px;
	background: linear-gradient(
		90deg,
		#f84c69,
		#f79759,
		#ebe478,
		#a0fb77,
		#64ed97,
		#57b2f3,
		#e475f3,
		#f84c69,
		#f79759,
		#ebe478,
		#a0fb77,
		#64ed97,
		#57b2f3,
		#e475f3,
		#f84c69
	);
	background-size: 400% 100%;
	animation: gradient 120s linear infinite forwards;
	color: #fff;
	height: 536px;
	position: relative;
	@include lg {
		height: 450px;
	}
	@include md {
		height: 370px;
	}
	@include sm {
		height: 242px;
	}
	@include xs {
		// width: 320px;
		height: 222px;
	}
	@keyframes gradient {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: 400% 0%;
		}
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url('../../assets/images/banner_grid.png');
		background-size: 3.6rem;
		opacity: 0.25;
	}

	.content {
		// @include flexColumn;
		// @include flexCenter;
		height: 100%;
		width: 100%;
		max-width: 1800px;
		margin-top: 50px;
		// margin: 0 50px;
		padding: 0;
		@include xl {
			
			max-width: 1700px;
			margin-top: 40px;
			padding: 0;
		}
		@include lg {
			margin: 0;
			// padding: 0 7%;
		}
		@include sm{
			margin-top:10px;
		}
		@include xs{
			margin-top:10px;
		}

		.heading {
			font-family: 'Poppins ExtraBold';
			text-align: center;
			text-transform: uppercase;
			font-size: 2.3rem;
			letter-spacing: 0.1rem;
			margin-top: 10px;
			@include lg {
				font-size: 1.3rem;
			}
			@include md {
				font-size: 1.3rem;
			}
			@include sm {
				font-size: 1rem;
			}
			@include xs {
				font-size: 0.8rem;
			}
		}

		.labIcons {
			display: block;
			width: auto;
			height: 100%;
			margin: 0 auto;
			// @include xs {
			// 	position: absolute;
			// 	left: 0;
			// }
		}
	}
	.hexagonbg img{
		width: 100%;
	}
	.adFormatCarouselSection {
		width: 54%;
    position: absolute;
    top: 3%;
    margin: 0px 23%;
    display: flex;
		/* align-items: flex-start; */
	}
	.hexagonbg img {
		width: 100%;
	}
	.hexagonbg {
		width: 56%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: nowrap;
		@include xs {
			// padding: 5px 15px;
			width:100%;
			margin-top: 1%;
		}
		@include sm {
			// padding: 5px 15px;
			width:100%;
			margin-top: 1%;
		}
	}
	.topBannerContainer {
		display: flex;
		border: 0px solid red;
		width: 100%;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
		flex-direction: row;
		position: absolute;
		margin-top: 8px;
		@include xs {
			// padding: 5px 15px;
			margin-top: 8px;
		}
		@include sm {
			// padding: 5px 15px;
			margin-top: 8px;
		}
	}
	.banner .adFormatCarouselSection img{
		width: 100%;
		height: fit-content;
	}
	.creativeCarousel{
		position: absolute;
		width: 90%;
		/* margin: 18% 4%; */
		position: absolute;
		width: 88%;
		top: 9%;
		left: 6%;
	}
	.leftPart {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		font-family: 'Poppins ExtraBold';
		position: relative;
		left: 5%;
		font-size: 3rem;
	}
	.rightPart {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		font-family: 'Poppins ExtraBold';
		position: relative;
		right: 5%;
		font-size: 3rem;
	}
	.button {
		font-family: 'Poppins';
    margin-top: 15px;
    background-color: transparent;
    color: #ffffff;
    border-radius: 50px;
    text-transform: none;
    /* padding: 1px 4px; */
    /* box-shadow: 0 0 3px 0px #ffffffeb; */
    /* border: 1px solid white; */
    background: url(/static/media/visitgallerybtn.3f597b94.png);
    background-size: auto 100%;
    margin: 0 auto;
    width: 14%;
    display: flex;
    margin-top: 2%;
    /* height: 21%; */
    background-repeat: no-repeat;
    text-align: center;
		@include lg {
			font-size: 0.6rem;
		}
		@include md {
			// padding: 5px 15px;
			width:34%;
		}
		@include sm {
			// padding: 5px 15px;
			width:25%;
		}
		@include xs {
			// padding: 5px 15px;
			width:25%;
		}
		img{
			width: 100%;
			opacity:0
		}
	}
	.galleryViewContainer {
		display: flex;
		text-align: center;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
	}
	.button:hover{
		// background-color: transparent !important;
		// box-shadow: 0px 0px 7px white; 
		background: url(../../assets/images/visitgalleryhoverbtn.png);
		background-size: 100% auto;
	}
	.innerContainer{
		width: 100%;
		border:1px solid black;
		height: 100%;;
	}
	.topDetails{
		position: relative;
	}
	.galleryIcon {
		position: absolute;
    width: 100%;
    text-align: center;
	margin-top: 18%;
		@include xs {
			// padding: 5px 15px;
			margin-top: 36%;
		}
		@include sm {
			// padding: 5px 15px;
			margin-top: 36%;
		}
	}
	.siteName{
		display: flex;
		justify-content: center;
		
		font-family: 'Poppins ExtraBold';
		
		width: 55%;
		
		align-content: center;
		position: relative;
		margin: 0 auto;
		margin-top:1%;
		@include lg {
			font-size: 1.3rem;
		}
		@include xl{
			font-size: 4.3rem;
		}
		@include md {
			font-size: 3.3rem;
		}
		@include sm {
			font-size: 2rem;
			width: 97%;
		}
		@include xs {
			font-size: 2rem;
			width: 97%;
		}
		img{
			width: 100%;
		}
	}
	.icon {
		display: flex;
		margin: 0 auto;
		width: 15%;
		flex-wrap: nowrap;
		flex-direction: row;
		justify-content: center;
		margin-top: 9%;
		@include sm {
			width: 59%;
			margin-top:25%;
		}
		@include xs {
			width: 59%;
			margin-top:25%;
		}
	}
	.icon img{
		width: 100%;
	}
}
