@import './global';

.loginForm {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 15px 0;
	.loginItems {
		text-align: center;
		justify-content: center;
		flex-wrap: wrap;
		flex: 1 100%;
		@include xs {
			width: 100%;
		}
		.input {
			width: 320px;
			padding: 7px;
			margin-top: 10px;
			border: 0px;
			background-color: #ececec;
			border-left: 4px solid #ea5252;
			font-family: 'Poppins Medium';
			color: #636363;
			@include xs {
				width: 100%;
				font-size: 0.7rem;
			}
		}
	}
	.button {
		font-family: 'Poppins Medium';
		margin-top: 25px;
		letter-spacing: 2px;
		background-color: #ea5252;
		color: #ffffff;
		border-radius: 50px;
		text-transform: none;
		padding: 5px 60px;
		@include xs {
			font-size: 0.7rem;
		}
	}
	.errorMessage {
		flex: 1 100%;
		text-align: center;
		font-size: 0.75rem;
		margin-top: 15px;
		font-family: 'Poppins Regular';
		color: #ea5252;
		@include xs {
			font-size: 0.6rem;
		}
	}
}
.modalBox {
	width: 60%;
	@include md {
		width: 90%;
	}
	@include xs {
		width: 98%;
	}
}
.loginModal {
	background: url('../assets/images/body_grid.png');
	background-size: 1.8rem;
	flex-flow: wrap;
	border-top: 1px solid #e3e3e3;
	border-left: 1px solid #e3e3e3;
	border-right: 1px solid #e3e3e3;
	padding: 30px 10px;
	display: flex;
	justify-content: center;
	@include xs {
		width: 100%;
	}
	.logoContainer {
		display: flex;
		justify-content: center;
		.logo {
			width: 60%;
			@include lg {
				width: 80%;
			}
			@include xs {
				width: 95%;
			}
		}
	}
	.loginBoxContainer {
		width: 60%;
		background: #ffffff;
		margin-top: 40px;
		padding: 2px;
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 10px 41.65px 7.35px rgba(88, 88, 88, 0.08);

		@include xl {
			width: 75%;
		}
		@include lg {
			width: 85%;
		}
		@include xs {
			width: 100%;
		}

		.loginBox {
			border: 1px solid #e3e3e3;
			padding: 20px;
			@include xs {
				padding: 10px;
			}

			.welcomeContainer {
				display: flex;
				justify-content: center;

				.lockIcon {
					width: 30px;
					height: 42px;
					margin-right: 15px;

					@include lg {
						width: 25px;
						height: 35px;
					}
					@include xs {
						width: 20px;
						height: 28px;
					}
				}
				.welcomeText {
					font-size: 0.95rem;
					font-family: 'Poppins Regular';
					color: #636363;
					@include lg {
						font-size: 0.85rem;
					}
					@include xs {
						font-size: 0.7rem;
					}
				}
			}
		}
	}
}
.borderBottom {
	width: 100%;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	border-bottom: 3px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(
		90deg,
		#f84c69,
		#f79759,
		#ebe478,
		#a0fb77,
		#64ed97,
		#57b2f3,
		#e475f3
	);
}
